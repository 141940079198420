// itemFormHandlers.ts
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { activateItem, deleteItem, upsertItem } from "../../../api/Items";
import { awsConfig, s3Config, s3ConfigImage } from "../../../aws-export";
import { ItemFormValues } from "../../../types/MenuItem";
import { changeExtensionToJPG } from "../../../utils/changeExtensionToJPEG";

// S3Client の初期化（必要ならここで一度だけ実施）
const s3Client = new S3Client({
  region: s3Config.REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: s3Config.REGION }),
    identityPoolId: awsConfig.IDENTITY_POOL_ID,
  }),
});

/**
 * S3 に画像をアップロードする関数
 */
export const uploadToS3 = async (file: File): Promise<string> => {
  if (
    !/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/.test(file.name) ||
    !/(jpg|jpeg|png|gif)$/.test(file.type)
  ) {
    alert(".jpg, .png形式の画像ファイルを選択してください");
    throw new Error(".jpg, .png形式の画像ファイルを選択してください");
  }

  // ファイル名からスペースを削除
  let filename = file.name.replace(/\s+/g, "");

  await s3Client.send(
    new PutObjectCommand({
      Bucket: s3Config.BUCKET,
      Key: filename,
      Body: file,
      ContentType: file.type,
      ACL: "private",
    })
  );
  return filename;
};

/**
 * 商品の登録・更新を行うハンドラー
 */
export const handleOnSubmit = async (
  data: ItemFormValues,
  mode: "create" | "edit",
  reset: () => void,
  toast: (options: any) => void,
  onUpdateFromList?: () => void
) => {
  const message = mode === "edit" ? "更新" : "登録";
  try {
    // 画像ファイルがある場合は S3 にアップロードして URL を生成する
    if (data.imageFile && data.imageFile.length > 0) {
      const filename: string = await uploadToS3(data.imageFile[0]);
      data.image = s3ConfigImage.URL_PREFIX + changeExtensionToJPG(filename);
    }

    await upsertItem(data, reset);
    toast({
      position: "top",
      title: `商品の${message}完了`,
      description: `商品名: ${data.title}の${message}が完了しました`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    if (onUpdateFromList) {
      onUpdateFromList();
    }
  } catch (error) {
    console.error(error);
    toast({
      position: "top",
      title: `商品の${message}失敗`,
      description: `商品名: ${data.title}の${message}が失敗しました。ログインし直してから、再実行ください。`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  }
};

/**
 * 商品をメニューに表示する／非表示にするハンドラー
 */
export const handleOnToggleActive = async (
  data: ItemFormValues,
  isActive: boolean,
  toast: (options: any) => void
) => {
  try {
    await activateItem(data, isActive);
    toast({
      position: "top",
      title: `商品の${isActive ? "表示" : "非表示"}完了`,
      description: `商品名: ${data.title}の${
        isActive ? "表示" : "非表示"
      }が完了しました`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  } catch (error) {
    toast({
      position: "top",
      title: `商品の${isActive ? "表示" : "非表示"}失敗`,
      description: `商品名: ${data.title}の${
        isActive ? "表示" : "非表示"
      }が失敗しました。ログインし直してから、再実行ください。`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  }
};

/**
 * 商品削除用のハンドラー
 */
export const handleOnDelete = async (
  data: ItemFormValues,
  reset: () => void,
  toast: (options: any) => void,
  onDeleteFromList?: (title: string, categoryName: string) => void
) => {
  try {
    await deleteItem(data, reset);
    toast({
      position: "top",
      title: `商品の削除完了`,
      description: `商品名: ${data.title}の削除が完了しました`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    if (onDeleteFromList) {
      onDeleteFromList(data.title!, data.categoryName!);
    }
  } catch (error) {
    toast({
      position: "top",
      title: `商品の削除失敗`,
      description: `商品名: ${data.title}の削除に失敗しました。ログインし直してから、再実行ください。`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
    console.error(error);
  }
};
