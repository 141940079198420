// ItemForm.tsx
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Select,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CATEGORY_NAMES, ItemFormValues } from "../../../types/MenuItem";
import { MenuCard } from "../../Menu/MenuCard";

// ハンドラーのインポート
import {
  handleOnDelete,
  handleOnSubmit,
  handleOnToggleActive,
} from "./itemFormHandlers";

interface ItemFormProps {
  initialValues: Partial<ItemFormValues>;
  mode: "create" | "edit";
  onDeleteFromList?: (title: string, categoryName: string) => void;
  onUpdateFromList?: () => void;
  displayOnDelete?: boolean;
}

export const ItemForm: FC<ItemFormProps> = ({
  initialValues,
  mode,
  onDeleteFromList,
  onUpdateFromList,
  displayOnDelete = true,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ItemFormValues>({ defaultValues: initialValues });
  const toast = useToast();

  // ここではハンドラーに必要な引数（data, mode/reset/toast など）を渡すだけ
  const onSubmit: SubmitHandler<ItemFormValues> = async (itemformValues) => {
    await handleOnSubmit(itemformValues, mode, reset, toast, onUpdateFromList);
  };

  const onToggleActive: SubmitHandler<ItemFormValues> = async (
    itemformValues
  ) => {
    // 現在の isActive の状態に応じて切り替え
    await handleOnToggleActive(itemformValues, !initialValues.isActive, toast);
  };

  const onDelete: SubmitHandler<ItemFormValues> = async (itemformValues) => {
    await handleOnDelete(itemformValues, reset, toast, onDeleteFromList);
  };

  return (
    <VStack
      align="stretch"
      spacing={4}
      backgroundColor={initialValues.isActive ? "none" : "gray"}
    >
      <MenuCard
        title={initialValues.title ?? ""}
        price={initialValues.price ?? ""}
        image={initialValues.image ?? ""}
        categoryName={initialValues.categoryName ?? ""}
        description={initialValues.description ?? null}
        cardSize="sm"
      />

      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          id="item-title"
          isRequired
          isDisabled={mode === "edit"}
          isInvalid={!!errors.title}
        >
          <FormLabel>商品名</FormLabel>
          <Input
            {...register("title", {
              required: "商品名 は必須項目です",
              maxLength: {
                value: 15,
                message: "商品名は15文字以内で入力してください",
              },
            })}
          />
          <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          id="item-categoryName"
          mt={4}
          isRequired
          isDisabled={mode === "edit"}
          isInvalid={!!errors.categoryName}
        >
          <FormLabel>カテゴリー</FormLabel>
          <Select
            {...register("categoryName", {
              required: "カテゴリー は必須項目です",
            })}
          >
            <option value="">カテゴリー選択</option>
            {CATEGORY_NAMES.map((category, index) => (
              <option key={index} value={category.name}>
                {category.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.categoryName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          id="item-price"
          mt={4}
          isRequired
          isInvalid={!!errors.price}
        >
          <FormLabel>価格</FormLabel>
          <Input {...register("price", { required: "価格 は必須項目です" })} />
          <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
        </FormControl>

        <FormControl id="item-imageFile" mt={4} isInvalid={!!errors.imageFile}>
          <FormLabel>Image File</FormLabel>
          <Input type="file" {...register("imageFile")} />
          <FormErrorMessage>{errors.imageFile?.message}</FormErrorMessage>
        </FormControl>

        {mode === "edit" && (
          <Box>
            <FormLabel>画像URL</FormLabel>
            <Link href={initialValues.image} isExternal>
              {initialValues.image}
              <ExternalLinkIcon mx="2px" />
            </Link>
          </Box>
        )}

        <FormControl id="item-description" mt={4}>
          <FormLabel>商品説明</FormLabel>
          <Textarea {...register("description")} />
        </FormControl>

        <Button type="submit" colorScheme="blue" mt={4}>
          {mode === "edit" ? "更新" : "登録"}
        </Button>

        {mode === "edit" && (
          <Button
            type="button"
            colorScheme={initialValues.isActive ? "gray" : "yellow"}
            mt={4}
            onClick={handleSubmit(onToggleActive)}
          >
            {initialValues.isActive ? "メニューから外す" : "メニューに載せる"}
          </Button>
        )}

        {mode === "edit" &&
          initialValues.isActive === false &&
          displayOnDelete && (
            <Button
              type="button"
              colorScheme="red"
              mt={4}
              onClick={handleSubmit(onDelete)}
            >
              削除
            </Button>
          )}
      </Box>
    </VStack>
  );
};
